let BASE_URL = '';
let TITLE = '小莫陋室';
let KEYWORDS = '小莫陋室';
let DESCRIPTION = '小莫陋室';

if (process.env.NODE_ENV == 'development') {
    // 开发环境
	// BASE_URL = 'http://119.3.1.143:9888'
    BASE_URL = 'http://1.94.148.38:9888'
    // BASE_URL = 'http://local.blog.com'
} else if (process.env.NODE_ENV === 'production') {
    // 生产环境
	// BASE_URL = 'http://119.3.1.143:9888'
    BASE_URL = 'http://1.94.148.38:9888'
    // BASE_URL = 'http://local.blog.com'
}

export {
    BASE_URL,
    TITLE,
    KEYWORDS,
    DESCRIPTION
}
